import { ContainerMaxWidth } from '@hypersay/ui';
import React, { ReactNode } from 'react';

import SEO from '../../components/seo';
import T from '../../components/T';
import LayoutBlog from '../../layouts/blog';

import { withPrefix } from 'gatsby';
import * as styles from './tips.module.scss';

interface TipProps {
  anchor: string;
  title: string;
  children: ReactNode;
}

const Tip = ({ anchor, title, children }: TipProps) => (
  <section className={styles.sectionContainer}>
    <div className={styles.anchorTarget} id={anchor}></div>
    <h2>{title}</h2>
    {children}
  </section>
);

const Business = () => (
  <LayoutBlog>
    <SEO
      title="Hypersay Blog - Tips&Tricks to succeed in using interactive presentations"
      description="Read articles on how to build interactive presentations and how to use Hypersay effectively."
      keywords={[
        'live presentation',
        'blog',
        'tips',
        'tips&tricks',
        'knowledge',
        'learn',
        'interactive presentations',
        'business',
        'corporate',
        'training',
        'townhall meetings',
        'powerpoint',
        'google slides',
      ]}
    />
    <ContainerMaxWidth className={styles.container} maxWidth="1200px">
      <article className={styles.content}>
        {/* tslint:disable: max-line-length */}

        <T tagName="h1" id="Tips & Tricks" />
        <Tip title="On account security" anchor="security">
          <p>
            All the major websites on the Internet that use standard
            password-based security have been hacked. Hackers are looking for
            sensitive data such as emails, passwords and credit card data. This
            is why we have built Hypersay in such a way that we do not store any
            password or credit card data.
          </p>
          <p>
            Instead of passwords we use either Single Sign On (from Facebook,
            Google or LinkedIn) or a security measure called One Time Password.
            OTP is, in fact, a temporary unique code which we communicate with
            you over your email. If you indeed own the email address provided
            and you have access to your inbox, you will also find the code we
            have sent. Next time you want to authenticate your account, we send
            another unique code.
          </p>
          <p>
            As far as credit card data is concerned, we are using a highly
            secure service called Stripe that manages all transactions and
            credit card operations. Everything is done on and through their own
            secure servers. Hypersay does not store any information regarding
            your credit card or payments.
          </p>
          <p>
            Overall, all Hypersay data (presentations, slides, participant's
            data, etc.) is securely stored using primarily AWS (Amazon Web
            Services) but partially also Google Cloud Computing and Microsoft
            Azure.
          </p>
        </Tip>

        <Tip
          title="On supporting animations and videos in slides"
          anchor="slidesanimations"
        >
          <p>
            If you have videos already embedded within your slide presentation,
            you will need to insert those video links directly into Hypersay
            inside the Prepare function.
          </p>
          <p>
            Hypersay does NOT currently support animations. If you have
            animations inside your original slide presentation, they will not be
            transferred into Hypersay during the conversion process. Nor is
            there currently any possibility to add animations in the Prepare
            mode.
          </p>
        </Tip>

        <Tip title="On replacing or editing slides" anchor="slidesreplacement">
          <p>
            You cannot directly edit any slides inside Hypersay. You can move
            the positions of the slides and you can delete any slides while you
            are in the Prepare page.
          </p>

          <p>
            If you need to edit an individual slide, you will need to go back to
            the original file (PowerPoint, Google Slides or PDF) and make the
            edit. Then you can take a screenshot of the new slide and import the
            screenshot into Hypersay through the Prepare page. Do not forget to
            delete the slide with the error.
          </p>

          <img
            src={withPrefix('/images/blog/add-corrected-slide.gif')}
            alt="add a corrected slide in Hypersay"
          />
        </Tip>

        <Tip
          title="Adding interactions: between or below slides?"
          anchor="betweenorbelow"
        >
          <p>
            We highly recommend placing your interactions (polls, quiz,
            question, video, etc) as separate slides rather than underneath
            slides. Why?
          </p>
          <ol>
            <li>It is a cleaner and more professional experience</li>
            <li>Scrolling down on a mobile phones is cumbersome</li>
            <li>You can use a clicker to move your slides</li>
          </ol>
          <img
            src={withPrefix('/images/blog/add-interactivity.gif')}
            alt="Adding interactivity"
          />
        </Tip>

        <Tip title="Using animated GIFs" anchor="addgifs">
          <p>
            Hypersay allows you to insert GIF’s into your sessions. You can
            insert a GIF in the same way you insert an image, using the
            Interactive Image button in the Prepare Page.
          </p>
          <img
            src={withPrefix('/images/blog/using-animated-gifs.gif')}
            alt="Adding animated GIFs"
          />
        </Tip>

        <Tip title="Re-arrange slides" anchor="rearrange">
          <p>
            You can easily rearrange the order of your slides while inside the
            Prepare page. Just drag and drop your slides into position. See GIF
            below.
          </p>
          <img
            src={withPrefix('/images/blog/rearrange-slides.gif')}
            alt="Re arrange slides"
          />
        </Tip>

        <Tip title="Credits or subscriptions" anchor="creditsvssubscriptions">
          <p>
            A single credit can be purchased for $3.99 when you plan to make a
            presentation where there will be more than 10 participants.
          </p>
          <p>
            If you plan to use Hypersay more than 4 times in one month where
            there are more than 10 participants, it is worth purchasing a
            monthly subscription for $14.99. You can cancel the monthly
            subscription whenever you wish without any penalties.
          </p>
          <p>
            We offer an annual subscription for $119.88 which is equivalent to
            $9.99 per month.
          </p>
          <a href="/pricing">👉Check pricing page here</a>
        </Tip>

        <Tip title="Getting participants to join" anchor="joining">
          <p>
            To encourage Participants to join your Hypersay session display the
            Welcome Screen and frequently expand the QR code into Full Screen so
            Participants can join using the camera’s on their phones. They will
            enjoy saying ‘Hi’ to you while waiting for other Participants to
            join the session.
          </p>
          <img
            src={withPrefix('/images/blog/expand-qr-code.gif')}
            alt="expand qr code"
          />
          <div className={styles.twoImages}>
            <div>
              <img
                src={withPrefix('/images/blog/welcome-page-mockup.png')}
                alt="welcome page"
              />
            </div>
            <div>
              <img
                src={withPrefix('/images/blog/number-participants.png')}
                alt="participants number"
              />
            </div>
          </div>
          <p>
            You can monitor the number of Participants who have joined your
            session by looking at the number in the top right hand corner of the
            Welcome Screen.
          </p>
        </Tip>

        <Tip title="Use a clicker" anchor="clicker">
          <p>
            Hypersay works very well with a Clicker. Ensure your Clicker has
            enough batteries and the bluetooth connection is established between
            the computer and the Clicker. You can easily move Hypersay slides
            forwards and backwards using a Clicker.
          </p>
          <p>
            The two limitations to using a Clicker are that you cannot Freeze or
            Show Answers and you cannot scroll down a page. For both of these,
            you will need to go back to the computer touchpad.
          </p>
          <img src={withPrefix('/images/blog/clicker.jpg')} alt="a clicker" />
        </Tip>

        <Tip title="Collect and manage questions" anchor="questionwall">
          <p>
            The Question Wall inside Hypersay is a very powerful tool to be used
            during your presentation. Participants can ask questions and vote
            for questions they like. When a question is asked, a silver dot will
            appear above the Question icon in the Live Session.
          </p>
          <img
            src={withPrefix('/images/blog/silver-dot.png')}
            alt="silver dot for questions"
          />
          <p>
            Most ‘liked’ questions will appear on top.{' '}
            <em>
              If you wish, you can delete questions after you have answered
              them, but then the questions will not appear in the Reports
              section.
            </em>
          </p>
        </Tip>

        <Tip title="Where's my feedback?" anchor="feedback">
          <p>
            After you close the Hypersay session, participants can give you
            feedback about your session. They will see the final slide below
            which asks for a star rating and any comments about your session.{' '}
          </p>

          <p>
            This feedback is shared with you in the Reports Section as long as 3
            or more participants have provided you feedback.
          </p>

          <img
            src={withPrefix('/images/blog/feedback.png')}
            alt="give feedback"
          />
        </Tip>

        <Tip title="What is the digital pulse?" anchor="digitalpulse">
          <p>
            Inside the Reports Section, Hypersay has developed a graphical
            representation of a ‘Digital Pulse’. For each Participant, Hypersay
            records:
          </p>
          <ul>
            <li>The time inside the presentation</li>
            <li>The number of questions/quizzes/polls answered</li>
            <li>The number of questions asked using the question wall </li>
            <li>The number of notes taken</li>
          </ul>

          <img
            src={withPrefix('/images/blog/digital-pulse.png')}
            alt="digital pulse sample"
          />

          <p>
            Each parameter is ranked versus the participant with highest number
            during the session. Then each parameter is summed together to give a
            cumulative score which we call a ‘Digital Pulse’.
          </p>

          <p>
            For example, a participant X who never left a 10 minute long
            Hypersay session would be given a score of 1. If participant Y only
            stayed for cumulative 5 minutes inside the same session, they would
            be given a .50. If participant X answered 6 of the 6 polls, they
            would be given a 1. If participant Y only answered 2 polls, they
            would be given a .33.{' '}
          </p>

          <p>
            The Digital Pulse is an aggregate sum of all the 4 factors measured
            during a presentation. Hence the highest score available is 4, the
            lowest is zero.
          </p>

          <p>
            Digital Pulse is not a measure of participant engagement as everyone
            possess different methods of learning. However, the aggregate
            picture allows the Presenter to understand the level of engagement
            during the session.
          </p>
        </Tip>

        <Tip
          title="How to find a specific participant's contribution"
          anchor="specificuser"
        >
          <p>
            Hypersay provides a downloadable CSV file for all responses to any
            question, quiz or poll inside the presentation.
          </p>

          <p>
            In the Session Analytics, the ‘Show Activity’ button highlights the
            answers given by each participant.
          </p>

          <p>
            Furthermore, inside the Answers section, the ‘See Details’ button
            also highlights the specific answers given by each participant and
            all the answers for an individual question.
          </p>
        </Tip>

        <Tip
          title="Reusing a Hypersay presentation"
          anchor="reusingpresentation"
        >
          <p>
            If you want to use the same presentation multiple times and erase
            all the answers from the previous session, just hit Duplicate inside
            the dashboard.
          </p>
          <img
            src={withPrefix('/images/blog/duplicate-presentation.gif')}
            alt="duplicate presentation"
          />
        </Tip>

        <Tip title="View upcoming slides" anchor="viewupcomingslides">
          <p>
            If you want to quickly navigate to a remote slide, use the sidebar
            that show you thumbnails of all of your slides.
          </p>
          <img
            src={withPrefix('/images/blog/view-slide.gif')}
            alt="slides in sidebar"
          />
        </Tip>

        <Tip
          title="Adding / Modifying interactions while live"
          anchor="addinteractionslive"
        >
          <p>
            When you are live you can still modify the existing interactions or
            add new ones.
          </p>
          <img
            src={withPrefix('/images/blog/add-modify-interactions-live.gif')}
            alt="add / modify interactions while live"
          />
        </Tip>

        <Tip title="Freezing questions" anchor="freezequestions">
          <p>
            By default a participant can keep changing their answers to your
            polls / quizzes or open questions. If you need to prevent this from
            happening, you can use the "freeze question" button.
          </p>
          <img
            src={withPrefix('/images/blog/freeze-questions.gif')}
            alt="Freeze questions"
          />
        </Tip>
      </article>
      <nav>
        <div className={styles.tocHeader}>
          <T id="Content" />
        </div>
        <ul>
          <li>
            <a href="#security">On account security</a>
          </li>
          <li>
            <a href="#slidesanimations">
              On supporting animations and videos in slides
            </a>
          </li>
          <li>
            <a href="#slidesreplacement">On replacing or editing slides</a>
          </li>
          <li>
            <a href="#betweenorbelow">
              Adding interactions: between or below slides?
            </a>
          </li>
          <li>
            <a href="#addgifs">Using animated GIFs</a>
          </li>
          <li>
            <a href="#rearrange">Re-arrange slides</a>
          </li>
          <li>
            <a href="#creditsvssubscriptions">Credits or subscriptions</a>
          </li>
          <li>
            <a href="#joining">Getting participants to join</a>
          </li>
          <li>
            <a href="#clicker">Use a clicker</a>
          </li>
          <li>
            <a href="#questionwall">Collect and manage questions</a>
          </li>
          <li>
            <a href="#feedback">Where's my feedback?</a>
          </li>
          <li>
            <a href="#digitalpulse">What is the digital pulse?</a>
          </li>
          <li>
            <a href="#specificuser">
              How to find a specific participant's contribution
            </a>
          </li>
          <li>
            <a href="#reusingpresentation">Reusing a Hypersay pres entation</a>
          </li>
          <li>
            <a href="#viewupcomingslides">View upcoming slides</a>
          </li>
          <li>
            <a href="#addinteractionslive">
              Adding / Modifying interactions while live
            </a>
          </li>
          <li>
            <a href="#freezequestions">Freezing questions</a>
          </li>
        </ul>
      </nav>
      {/* tslint:enable: max-line-length */}
    </ContainerMaxWidth>
  </LayoutBlog>
);

export default Business;
